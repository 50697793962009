body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
margin:1px;
padding: 0px;
/*border-color: black;
border-style: solid;
border-width: 0.25px;*/
}

.dicon{
  margin: 0px 0px 0px 0px;
  cursor:pointer;
}

.dicone{
  margin: 0px 0px 0px 0px;
  user-select: none;
  cursor: default;
}

.pointer{
  cursor:pointer;
}


.cn{
  /*height: auto;*/
  background-color: #d00;
  height:20px
}

.cp{
  /*height: auto;*/
  background-color: green;
  height:20px
}
.c1{
  /*height: auto;*/
  background-color: rgb(108, 175, 119);
  height:20px
}

.cw{
  /*height: auto;*/
  /*background-color: green;*/
  height:20px
}

.commentData{
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.commenttitle{
  font-size: 18px;
  font-weight: bolder;
}
.commentauthor{
  font-size: 14px;
  font-weight: bolder;
  color: rgb(90, 90, 90);
}

.comment-textarea{
  height:"80px";
}

.usernamenav{
  font-weight: bolder;
  color: rgb(70, 70, 70);
}

.blacklink{
  color: black
}

#cross {
  width: 100px;
  height: 100px;
  position: relative;
 transform:rotate(45deg);
-ms-transform:rotate(45deg); /* IE 9 */
-webkit-transform:rotate(45deg); /* Safari and Chrome */
}

#cross:before, #cross:after {
 content: "";
 position: absolute;
 z-index: -1;
 background: #d00;
}

#cross:before {
 left: 50%;
 width: 30%;
 margin-left: -15%;
 height: 100%;
}

#cross:after {
 top: 50%;
 height: 30%;
 margin-top: -15%;
 width: 100%;
}

div.on_off_off
{
 background: rgb(150,110,120);
 font-family: segoe ui;
 font-size: 12px;
 font-weight: bold;
 padding: 5px 10px;
 border: 3px solid rgb(180, 140, 150);
 border-radius: 5px;
 -moz-border-radius: 5px;
 -webkit-border-radius: 5px;
 -khtml-border-radius: 5px;
 color: #fff;
 display: inline;
}
div.on_off_on
{
 background: rgb(110,150,120);
 font-family: segoe ui;
 font-size: 12px;
 font-weight: bold;
 padding: 5px 10px;
 border: 3px solid rgb(140, 180, 150);
 border-radius: 5px;
 -moz-border-radius: 5px;
 -webkit-border-radius: 5px;
 -khtml-border-radius: 5px;
 color: #fff;
 display: inline;
}

.fullScreen-preloader {
  display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eee;
	position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.preloader-background {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eee;
	/*position: fixed;*/
  z-index: 999;
  width: 100%;
  height: 100%;
}

.preloader-circle {
  width: calc(50% + 30px);
  margin-left:calc(50% - 30px);
}

.preloader-text {
  text-align: center;
  color: green;
  padding-top:120px;
  opacity: 0.8;
}


.blinking {
	animation: blinker 1.2s linear infinite;
}
@keyframes blinker {  
  50% { opacity: 0.1; }
}